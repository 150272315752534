import "./style.scss";
import React from "react";
import {Helmet} from 'react-helmet'
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";
import sanitise from "global/utils/sanitise";
import * as utils from 'global/utils/utils'


const cn = require('classnames')

const MikmakBlock = (props) => {
  if (props && props.data && props.data.fields) {
    const {
      MikmakConfigurationID,
      MikmakCopy,
      MikmakEan,
      MikmakLabel,
      MikmakType,
    } = fieldsArrayToKeyValueHash(props.data.fields);
    const pageType = props.pageType || '';

    const getDefaultId = (mmids) => {
      let id = '';

      if (mmids.includes(',')) {
        const strSplit = mmids.split(',');
        id = strSplit.length > 0 ? strSplit[0] : mmids;
      } else {
        id = mmids;
      }
      return id;
    };

    const handleClickGA = (e, widgetType = 'Drawer' | 'In-page',) => {
      const target = e.currentTarget;
      const dataset = target.dataset;
      const name = dataset.name || '';

      document.getElementById('swn-awe-popin-ctnr')?.setAttribute('data-ga-pagetype', pageType);
      utils.gaSubmit({
        event: 'content_interaction',
        journey_type: 'Product',
        interaction_type: pageType,
        interaction_name: name,
        page_type: pageType,
        widget_type: widgetType,
      });
    };

    const getValues = (values, wtbid, itemindex, retailer,) => {
      const objectVal = {
        currency: String(values.currency),
        value: Number(values.price),
        items: [
          {
            item_id: String(values.ean) || '',
            item_name: values.name || '',
            quantity: '1',
            price: Number(values.price) || '',
            affiliation: 'Online',
            coupon: undefined,
            discount: undefined,
            index: itemindex,
            item_brand: values.brand || "Captain Morgan",
            item_category: values.productLine || '',
            item_category2: values.name || '',
            item_category3: values.pkg || '',
            item_category4: undefined,
            item_category5: undefined,
            item_list_id: wtbid,
            item_list_name: undefined,
            item_variant: `${String(values.name)} ${String(values.name2)}`,
            retailer,
          },
        ],
      };

      return objectVal;
    };
    const mikMakGACallback = function (e) {
      let product = e.product;
      const retailer = e.retailer;

      const mikMakParentDiv = document.getElementById('swn-awe-popin-ctnr');
      const mikMakInPageWrapper = document.getElementById('mikmak_wrapper');
      const pageTypeFromAttribute =
        mikMakParentDiv?.getAttribute('data-ga-pagetype') ||
        mikMakInPageWrapper?.getAttribute('data-ga-pagetype') ||
        '';
      switch (e.action) {
        case 'close':
          mikMakParentDiv?.setAttribute('data-ga-pagetype', '');
          break;
        case 'clkloc':
          //this is for clicks on the direction button
          utils.gaSubmit({
            event: 'store_locator',
            product_id: product.ean,
          });
          break;
        // case 'clkRoute':
        //   //this is for clicks on the direction button
        //   utils.gaSubmit({
        //     event: 'content_interaction',
        //     journey_type: 'Product',
        //     interaction_type: 'Location',
        //     interaction_name: 'Directions',
        //     page_type: pageType ? pageType : mikmakIntegrationType,
        //     widget_type: 'Drawer',
        //   });
        //   break;
        case 'buy':
          //this is for clicks on the buy button
          product = {
            brand: String(product.brand) || '',
            categoryName: String(product.brand) || '',
            currency: String(product.currency) || '',
            id: String(product.id) || '',
            model: String(product.model) || '',
            name: String(product.name) || '',
            name2: String(product.name2) || '',
            pkg: String(product.pkg) || '',
            productLine: String(product.productLine) || '',
            range: String(product.range) || '',
            sourcePid: String(product.sourcePid) || '',
            price: Number(product.price) || 0,
            ean: String(product.ean) || '',
          };
          utils.gaSubmit({
            event: 'select_item',
            page_type: pageType ? pageType : mikmakIntegrationType,
            ecommerce: getValues(
              product,
              String(e.wtbid),
              (e.ids).indexOf(product.ean) + 1,
              retailer.name,
            ),
          });
          break;
        default:
      }
    };

    const loadCallbackGA = () => {
      try{
        if (!window.swnDataLayer) window.swnDataLayer = {};
        window.swnDataLayer.widget_callback = mikMakGACallback;
      } catch(e) {
        console.warn(e)
      }
    }
 

    return (
      <>
        <Helmet>
          <script
            id="mikmakscript"
            onReady={loadCallbackGA()}
            type="text/javascript"
            async
          >
            {`(function(e,d){try{var a=window.swnDataLayer=window.swnDataLayer||{};a.appId=e||a.appId,a.eventBuffer=a.eventBuffer||[],a.loadBuffer=a.loadBuffer||[],a.push=a.push||function(e){a.eventBuffer.push(e)},a.load=a.load||function(e){a.loadBuffer.push(e)},a.dnt=a.dnt!=null?a.dnt:d;var t=document.getElementsByTagName("script")[0],n=document.createElement("script");n.async=!0,n.src="//wtb-tag.mikmak.ai/scripts/"+a.appId+"/tag.min.js",t.parentNode.insertBefore(n,t)}catch(e){console.log(e)}}("66723359cfa99b31c6a41ab9", false));`}
          </script>
        </Helmet>

        {MikmakType === 'Navigation Item' &&
          <ul
            className="navigation__actions"
            role="menu"
          >
            <li role="none" className="navigation__actions-item">
              <button
                className="navigation__actions--buy"
                data-mm-default-id={getDefaultId(MikmakEan)}
                data-mm-ids={MikmakEan}
                data-mm-wtbid={MikmakConfigurationID}
                data-name={`${MikmakLabel || 'Buy Now'}`}
                onClick={(e) => {
                  handleClickGA(e, 'Drawer');
                }}
                role="menuitem"
                type="button"
              >
                {MikmakLabel || 'Buy Now'}
              </button>
            </li>
          </ul>
        }

        {MikmakType === 'Button' &&
          <button
            className="cta cta-product-promo"
            data-mm-default-id={getDefaultId(MikmakEan)}
            data-mm-ids={MikmakEan}
            data-mm-wtbid={MikmakConfigurationID}
            data-name={`${MikmakLabel || 'Buy Now'}`}
            onClick={(e) => {
              handleClickGA(e, 'Drawer');
            }}
            type="button"
          >
            {MikmakLabel || 'Buy Now'}
          </button>
        }

        {MikmakType === 'In Page' &&
          <div className={`mikmak__inpage`} data-ga-pagetype="Where to Buy" id="mikmak_wrapper">
            <div className="mikmak__heading ">
              {MikmakLabel &&
                <h2 className="mikmak__label">{MikmakLabel}</h2>
              }
              {MikmakCopy ? (
                <div
                  className="mikmak__copy text-center"
                  dangerouslySetInnerHTML={{
                    __html: MikmakCopy,
                  }}
                />
              ) : null}
            </div>
            <div
              className="mikmak__wrap"
              data-mm-ids={MikmakEan}
              data-mm-wtbid={MikmakConfigurationID}
              id="mikmak"
            />
          </div>
        }
      </>
    );

  }

  return false;
};

export default MikmakBlock;
